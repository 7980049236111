import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "setup-environment"
    }}>{`Setup Environment`}</h1>
    <p>{`These guides will try and assist you as much as possible in getting your environment up and running.
Feel free to also do your own research and contributing to these docs with a PR (pull request).`}</p>
    <h2 {...{
      "id": "required-software"
    }}>{`Required Software`}</h2>
    <ul>
      <li parentName="ul">{`Git`}</li>
      <li parentName="ul">{`Docker and docker-compose`}</li>
      <li parentName="ul">{`Node (15.x)`}</li>
      <li parentName="ul">{`Golang`}</li>
      <li parentName="ul">{`VsCode or WebStorm | and Goland (if coding Go)`}</li>
      <li parentName="ul">{`Git-town (or anything like it)`}</li>
    </ul>
    <h2 {...{
      "id": "linux-ubuntu"
    }}>{`Linux (Ubuntu)`}</h2>
    <p>{`This guide assumes you are running Ubuntu 20.04LTS desktop. It also assumes that you are using `}<inlineCode parentName="p">{`apt`}</inlineCode>{` and `}<inlineCode parentName="p">{`snap`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "installing-curl-for-ubuntu-linux"
    }}>{`Installing cURL for Ubuntu Linux`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo apt update && sudo apt upgrade
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo apt install curl
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl --version
`}</code></pre>
    <h3 {...{
      "id": "installing-git-using-apt-cache"
    }}>{`Installing Git Using APT Cache`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo apt update
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo apt upgrade
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo apt install git
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`git --version
`}</code></pre>
    <h3 {...{
      "id": "configuring-git-in-ubuntu-2004"
    }}>{`Configuring Git in Ubuntu 20.04`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`git config --global user.name "Your User Name"
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`git config --global user.email "Your Email Address"
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`git config --list
`}</code></pre>
    <h3 {...{
      "id": "install-docker-and-docker-compose"
    }}>{`Install Docker and docker-compose`}</h3>
    <p>{`Source: `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/ubuntu/"
      }}>{`https://docs.docker.com/engine/install/ubuntu/`}</a>{`
Don't forget to do these steps: `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/linux-postinstall/"
      }}>{`https://docs.docker.com/engine/install/linux-postinstall/`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo apt-get install \\
    apt-transport-https \\
    ca-certificates \\
    curl \\
    gnupg \\
    lsb-release

 curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo gpg --dearmor -o /usr/share/keyrings/docker-archive-keyring.gpg

echo \\
  "deb [arch=amd64 signed-by=/usr/share/keyrings/docker-archive-keyring.gpg] https://download.docker.com/linux/ubuntu \\
  $(lsb_release -cs) stable" | sudo tee /etc/apt/sources.list.d/docker.list > /dev/null

sudo apt-get update

sudo apt-get install docker-ce docker-ce-cli containerd.io

sudo groupadd docker

sudo usermod -aG docker $USER

newgrp docker

## verify docker

docker run hello-world
`}</code></pre>
    <details><summary><h4>Alternative Docker installation</h4></summary>
      <pre>1. Go to <a href="https://download.docker.com/linux/ubuntu/dists/]">https://download.docker.com/linux/ubuntu/dists/</a>, choose your Ubuntu version, </pre>
      <pre>then browse to pool/stable/, choose amd64, armhf, or arm64,</pre>
      <pre>and download the .deb file for the Docker Engine version you want to install.</pre>
      <pre>2. Install Docker Engine, changing the path below to the path where you downloaded the Docker package.</pre>
      <code>
sudo dpkg -i /path/to/package.deb
      </code>
      <pre>3. Verify that Docker Engine is installed correctly by running the hello-world image.</pre>
      <code>
sudo docker run hello-world
      </code>
      <code>
base=https://github.com/docker/machine/releases/download/v0.16.0 &&
curl -L $base/docker-machine-$(uname -s)-$(uname -m) >/tmp/docker-machine &&
sudo install /tmp/docker-machine /usr/local/bin/docker-machine
      </code>
      <code>
docker-machine --version
      </code>
      <code>
base=https://raw.githubusercontent.com/docker/machine/v0.16.0
for i in docker-machine-prompt.bash docker-machine-wrapper.bash docker-machine.bash
do
sudo wget "$base/contrib/completion/bash/$" -P /etc/bash_completion.d
done
      </code>
    </details>
    <h3 {...{
      "id": "generating-a-new-ssh-key-for-github"
    }}>{`Generating a new SSH key for GitHub`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`ssh-keygen -t ed25519 -C "your_email@example.com"
`}</code></pre>
    <p><em parentName="p">{`Press Enter`}</em></p>
    <h3 {...{
      "id": "adding-your-ssh-key-to-the-ssh-agent"
    }}>{`Adding your SSH key to the ssh-agent`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`eval "$(ssh-agent -s)"
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`ssh-add ~/.ssh/id_ed25519
`}</code></pre>
    <h3 {...{
      "id": "adding-an-ssh-key-to-your-github-account"
    }}>{`Adding an SSH key to your GitHub account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo apt-get install xclip
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`xclip -selection clipboard < ~/.ssh/id_ed25519.pub
`}</code></pre>
    <p>{`Then go to your browser, open GitHub, go to Settings, click SSH and GPG keys, click New SSH key and give it a name like "Personal Virtual Ubuntu Station"`}</p>
    <h3 {...{
      "id": "installing-nodejs"
    }}>{`Installing Node.js`}</h3>
    <p>{`To Download Node go to `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}><strong parentName="a">{`their website`}</strong></a>{` and click download.
Follow all the instructions.
Open VsCode and run the command `}<inlineCode parentName="p">{`npm i`}</inlineCode>{` to install.
And `}<inlineCode parentName="p">{`npm run dev`}</inlineCode>{` to run the website at `}<a parentName="p" {...{
        "href": "localhost:3000"
      }}>{`localhost:3000`}</a>{`.`}</p>
    <h3 {...{
      "id": "install-git-town"
    }}>{`Install Git-town`}</h3>
    <p>{`Source: `}<a parentName="p" {...{
        "href": "https://www.git-town.com/"
      }}>{`https://www.git-town.com/`}</a></p>
    <p>{`The easiest is through Homebrew, so if you don't have that yet, let's install it.
Follow the official guide `}<a parentName="p" {...{
        "href": "https://docs.brew.sh/Homebrew-on-Linux"
      }}>{`https://docs.brew.sh/Homebrew-on-Linux`}</a></p>
    <p>{`Then simply run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`brew install git-town
`}</code></pre>
    <h3 {...{
      "id": "install-golang"
    }}>{`Install Golang`}</h3>
    <p>{`Source: `}<a parentName="p" {...{
        "href": "https://golang.org/dl/"
      }}>{`https://golang.org/dl/`}</a></p>
    <p>{`Download the v1.16.3 or above.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`wget https://dl.google.com/go/go1.16.3.linux-amd64.tar.gz
sudo rm -rf /usr/local/go && tar -C /usr/local -xzf go1.16.3.linux-amd64.tar.gz
echo 'export PATH=$PATH:/usr/local/go/bin' >> $HOME/.profile
source $HOME/.profile
`}</code></pre>
    <h3 {...{
      "id": "install-vscode"
    }}>{`Install VsCode`}</h3>
    <p>{`Source: `}<a parentName="p" {...{
        "href": "https://code.visualstudio.com/"
      }}>{`https://code.visualstudio.com/`}</a></p>
    <p>{`I'm going to be using the snap store:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo snap install code --classic
`}</code></pre>
    <h2 {...{
      "id": "windows"
    }}>{`Windows`}</h2>
    <h3 {...{
      "id": "installing-virtualbox"
    }}>{`Installing VirtualBox`}</h3>
    <p>{`Download `}<a parentName="p" {...{
        "href": "https://ubuntu.com/download/desktop"
      }}>{`Ubuntu`}</a>{` from their website
Download `}<a parentName="p" {...{
        "href": "https://download.virtualbox.org/virtualbox/6.1.20/VirtualBox-6.1.20-143896-Win.exe"
      }}>{`VirtualBox 6.1.20 platform packages for Windows`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://download.virtualbox.org/virtualbox/6.1.20/Oracle_VM_VirtualBox_Extension_Pack-6.1.20.vbox-extpack"
      }}>{`VirtualBox 6.1.20 Oracle VM VirtualBox Extension Pack`}</a></p>
    <h3 {...{
      "id": "setting-up-virtualbox"
    }}>{`Setting Up VirtualBox`}</h3>
    <p>{`To install Ubuntu on VirtuaBox you can use `}<a parentName="p" {...{
        "href": "https://brb.nci.nih.gov/seqtools/installUbuntu.html"
      }}>{`this website`}</a>{` for help`}</p>
    <h3 {...{
      "id": "installing-vscode"
    }}>{`Installing VsCode`}</h3>
    <p>{`To Download VsCode go to `}<a parentName="p" {...{
        "href": "https://code.visualstudio.com/"
      }}><strong parentName="a">{`their website`}</strong></a>{` and click Download.
Follow all the instructions.`}</p>
    <h3 {...{
      "id": "installing-nodejs-1"
    }}>{`Installing Node.js`}</h3>
    <p>{`To Download Node go to `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`their website`}</a>{` and click download.
Follow all the instructions.
Open VsCode and run the command `}<inlineCode parentName="p">{`npm i`}</inlineCode>{` to install.
And `}<inlineCode parentName="p">{`npm run dev`}</inlineCode>{` to run the website at `}<a parentName="p" {...{
        "href": "localhost:3000"
      }}>{`localhost:3000`}</a>{`.`}</p>
    <h3 {...{
      "id": "installing-github-desktop"
    }}>{`Installing GitHub Desktop`}</h3>
    <p>{`Download `}<a parentName="p" {...{
        "href": "https://central.github.com/deployments/desktop/desktop/latest/win32"
      }}><strong parentName="a">{`it`}</strong></a>{` and follow all the instructions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      